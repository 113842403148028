<template>
    <div class="agreement-list-class content-height">
        <section class="center-detail">
            <!-- 协议表格 -->
            <div>
                <el-table
                        :data="agreementList"
                        tooltip-effect="dark"
                        class="w100"
                >
                    <el-table-column
                            label="协议名称"
                    >
                        <template slot-scope="scope">
                            <div>{{
                                handleEmpty(scope.row.argumentName)
                                }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="create_time"
                            label="签署时间"
                            show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div v-if="createTime">{{ formatTime(createTime,'Y/M/D h:m:s')}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="操作">
                        <template slot-scope="scope">
                             <span
                                     class="green-font"
                                     @click="openPages(scope.row.url)">查看详情</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </section>
    </div>
</template>

<script>
    import {storeManagement} from "@/assets/js/api";
    import {handleResponseErrors} from "@/assets/js/utils";
    import {mapState} from "vuex";

    export default {
        name: "agreementList",
        data() {
            return {
                createTime: '',
                agreementList: [
                    {
                        id: '1',
                        argumentName: '服务条款',
                        url:'PrivacyPolicy'
                    },
                    {
                        id: '2',
                        argumentName: '隐私政策',
                        url:'PrivacyPolicy'
                    },
                     {
                        id: '3',
                        argumentName: '建站服务协议',
                        url:'SiteConstructionServiceAgreement'
                    }
                ],
            };
        },
        computed: {...mapState(["userMsg"])},
        created() {
            if (localStorage.getItem('fpp-userMsg') && JSON.parse(localStorage.getItem('fpp-userMsg')).createTime) {
                this.createTime = JSON.parse(localStorage.getItem('fpp-userMsg')).createTime
            }
        },
        mounted() {
            console.log('localStorage.getItem(\'createTime\')', JSON.parse(localStorage.getItem('fpp-userMsg')).createTime)
        },
        methods: {
            openPages(pages) {
                let url = window.location.origin + '/register/agreement/' + pages;
                window.open(url, "_blank");
            },
            /**
             * 时间戳转化为年 月 日 时 分 秒
             * number: 传入时间戳
             * format：返回格式，支持自定义，但参数必须与formateArr里保持一致
             */
            formatTime(number, format) {
                let formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
                let returnArr = [];
                let date = new Date(number * 1000);
                returnArr.push(date.getFullYear());
                returnArr.push(this.formatNumber(date.getMonth() + 1));
                returnArr.push(this.formatNumber(date.getDate()));

                returnArr.push(this.formatNumber(date.getHours()));
                returnArr.push(this.formatNumber(date.getMinutes()));
                returnArr.push(this.formatNumber(date.getSeconds()));

                for (let i in returnArr) {
                    format = format.replace(formateArr[i], returnArr[i]);
                }
                return format;
            },

            //数据转化
            formatNumber(n) {
                n = n.toString()
                return n[1] ? n : '0' + n
            },
            handleEmpty(value) {
                if (value) {
                    return value
                } else {
                    return '- -'
                }
            }
            ,
            enterStoreDetail(index, data) {
            }
            ,
        },
    }
    ;
</script>

<style scoped lang="scss">
    @import "@/assets/css/common.scss";

    .content-height {
        min-height: calc(100vh - 429px);
    }

    .agreement-list-class {
        .w100 {
            width: 100%;
        }
        .green-font {
            color: $green;
            cursor: pointer;
        }
    }
</style>
